<template>
  <div id="signup">         
    
  <section class="container mt-5">
    <h1 class="mb-5">Reset Password</h1>

    <div class="row justify-content-center">
    
    <Form @submit="onSubmit" :validation-schema="schema">

      <TextInput
        name="password"
        type="password"
        label="New Password"
        placeholder="Your password"
      />
      <TextInput
        name="confirm_password"
        type="password"
        label="Confirm Password"
        placeholder="Type it again"
      />

      <button type="submit" class="btn btn-custom">Login</button>

      <div class="login-social row d-block justify-content-center mt-5">
        
        <ul class="d-flex justify-content-center">
          <li class="list-group-item">
            <!-- google	 -->
            <button v-on:click="loginWithGoogle" type="button" class="login-with-google-btn" >
              Sign in with Google
            </button>
          </li>
          <!-- <li class="list-group-item">
            <button v-on:click="loginWithFacebook" type="button" class="login-with-facebook-btn" >
              Sign in with Facebook
            </button>

          </li> -->
        </ul>

      </div>

    </Form>


    </div>
  </section>

  <Footer/>
  </div>
</template>

<script>
import Api from '../scripts/api'

import Footer from '../components/global/Footer'


import { Form } from "vee-validate";
import * as Yup from "yup";
import TextInput from "../components/Form/TextInput";


export default {
    components: {
        Footer,
        Form,
        TextInput
    },
    props: {
      account: Object
    },
    setup() {
        var schema = Yup.object().shape({
          password: Yup.string().min(6).required(),
          confirm_password: Yup.string()
            .required()
            .oneOf([Yup.ref("password")], "Passwords do not match"),
        });
        return {
          schema,
        };
    },
    data() {
      return {
        password: '',
        confirmPassword: ''
      }
    },
    methods: {
      onSubmit: function(values) {
        this.change(values.password, values.confirm_password);
      },
      change: async function(password, confirmPassword) {
        if(password != confirmPassword) return this.$toast.error('New password and Confirm password fields do not match.')
        
        var result = await Api.setPassword(password, this.$route.query.hash)
        // if(result.status == 422) return this.$toast.error('The new password is not strong enough, try another one.')
        
        if(result.status == 200) {
          this.$router.push('/login');
        }
      }
    },
}
</script>

<style>

:root {
  --primary-color: #0071fe;
  --error-color: #f23648;
  --error-bg-color: #fddfe2;
  --success-color: #21a67a;
  --success-bg-color: #e0eee4;
}
.submit-btn {
  background: var(--primary-color);
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  display: block;
  width: 100%;
  border-radius: 7px;
  margin-top: 40px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.submit-btn:hover {
  transform: scale(1.1);
}
</style>
